/** ****************************** Import libs *********************************** */
import { putDataApi } from "./actions";
import { URL_CONSTANTS } from "./urls";

export const updateUserData = (params, id) =>
  putDataApi(URL_CONSTANTS.users, params, id);

export const updateOurCustomer = (params, id) =>
  putDataApi(URL_CONSTANTS.ourCustomers, params, id);

export const updateEmployeeReview = (params, id) =>
  putDataApi(URL_CONSTANTS.employeeReview, params, id);

export const updateOrganizationData = (params, id) =>
  putDataApi(URL_CONSTANTS.organizations, params, id);

export const updateUserProfileData = (params, id) =>
  putDataApi(URL_CONSTANTS.userProfile, params, id);

export const updateWorkInfoData = (params, id) =>
  putDataApi(URL_CONSTANTS.workInfo, params, id);

export const updateTeamInfoData = (params, id) =>
  putDataApi(URL_CONSTANTS.teamInfo, params, id);

export const updateUserRoles = (params, id) =>
  putDataApi(URL_CONSTANTS.settings, params, id);

// export const updateRoleAndAccessData = (params, id) =>
//   putDataApi(URL_CONSTANTS.rolesAndAccess, params, id);

export const updateCandidateData = (params, id) =>
  putDataApi(URL_CONSTANTS.candidates, params, id);

export const updateCandidateRoleData = (params, id, roleId, role, method) =>
  putDataApi(URL_CONSTANTS.candidates, params, id, roleId, role, method);

// export const updateTasksData = (params, id) =>
//   putDataApi(URL_CONSTANTS.tasks, params, id);

// export const updateOnboardingStatusData = (params, id) =>
//   putDataApi(URL_CONSTANTS.onboardingStatus, params, id);

export const updateJobPostData = (params, id) =>
  putDataApi(URL_CONSTANTS.jobposts, params, id);

export const updateBlogPostData = (params, id) =>
  putDataApi(URL_CONSTANTS.blogs, params, id);
 
export const updateUserSetttingPostData = (params, id) =>
  putDataApi(URL_CONSTANTS.usersettings, params, id);

export const updateAddressData = (params, id) =>
  putDataApi(URL_CONSTANTS.organizationdetails, params, id);

export const updateCompanyStatisticsData = (params, id) =>
  putDataApi(URL_CONSTANTS.companystatistics, params, id);

export const updateSocialMediaData = (params, id) =>
  putDataApi(URL_CONSTANTS.socialmedialink, params, id);
export const updateUserProfileImage = (params) => putDataApi(URL_CONSTANTS.uploadProfileImage, params);

export const updateBlogCategoryData = (params, id) =>
  putDataApi(URL_CONSTANTS.blogCategories, params, id);

export const updateJobPositionData = (params, id) =>
  putDataApi(URL_CONSTANTS.jobPosition, params, id);

export const updateTestimonialData = (params, id) =>
  putDataApi(URL_CONSTANTS.testimonials, params, id);

export const updatePpcTypeData = (params, id) =>
  putDataApi(URL_CONSTANTS.ppcSettings, params, id);

export const updatePpcRegionData = (params, id) =>
  putDataApi(URL_CONSTANTS.ppcSettings, params, id);

export const updatePortfolioData = (params, id) =>
  putDataApi(URL_CONSTANTS.campaignPdf, params, id);

